import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulStaffMembers(sort: { fields: [order], order: ASC}) {
        edges {
          node {
            fullName
            order
            jobTitle
            bio {
              bio
            }
            portrait {
              gatsbyImageData(
                layout: CONSTRAINED
                formats: [AUTO, WEBP]
                aspectRatio: 1
                width: 222
                quality: 100
                cropFocus: FACE
                resizingBehavior: THUMB
              )
            }
          }
        }
      },
    }
  `)

  return(
    <Layout>
          <SEO 
            title="Premier ED Treatment: Staff Members at the Clinic" 
            description="At Multnomah Medical Clinic, we have dedicated staff members who are qualified and eager to assist with ED related treatment."
          />
          <div className="flex justify-center text-center">
            <div className="text-center px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl">
              <h1 className="text-2xl text-gray-900 font-semibold leading-snug">Learn More About Our Staff</h1>
              <Spacer />
              <div className="flex justify-center max-width w-full flex-wrap">
                  {
                    data.allContentfulStaffMembers.edges.map((staffMember, index) => {
                      const memberName = staffMember.node.fullName
                      const memberLink = memberName.substring(0, memberName.indexOf(' ')).toLowerCase()
                      return(
                      <div 
                        className="w-full flex flex-col sm:flex-row text-left mb-4" 
                        key={staffMember + index}
                        id={memberLink}
                      >
                        <GatsbyImage 
                            image={staffMember.node.portrait.gatsbyImageData} 
                            className="w-full sm:w-1/4 h-1/4"
                            imgStyle={{ objectFit: 'contain', objectPosition: '50% 0%', marginTop: '12px'}}
                            alt={`Portrait of Multnomah Medical staff member ${memberName}`}
                        />
                        <div className="w-full sm:w-3/4 ml-2">
                          <p className="text-lg text-gray-900 sm:pl-2 pt-2 uppercase leading-tight">
                            {memberName}
                          </p>
                          <p className="text-lg text-gray-900 leading-normal sm:pl-2 font-semibold">
                            {staffMember.node.jobTitle}
                          </p>
                          <div style={{ overflow: 'hidden' }} className="staff-bio-deactivated">
                          <p className=" text-gray-900 leading-normal sm:pl-2 pt-1">
                            {staffMember.node.bio !== null ? staffMember.node.bio.bio : ''}
                          </p>
                          </div>
                        </div>
                      </div>
                    )})
                  }
              </div>
            </div>
          </div>
    </Layout>
  )
}

export default AboutUs
